import MaiDashboard from './Dashboard';

function App() {
  const lookerReportUrl = "https://lookerstudio.google.com/embed/reporting/c7a06f25-3fdd-477b-b03e-342651fe4ffa/page/0s1BE";

  return (
    <MaiDashboard lookerReportUrl={lookerReportUrl} />
  );
}

export default App;