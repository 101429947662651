import React, { useState } from 'react';
import { Activity, BarChart2, Users, Search, Settings, HelpCircle, LogOut } from 'lucide-react';

interface DashboardProps {
  lookerReportUrl: string;
}

const Dashboard: React.FC<DashboardProps> = ({ lookerReportUrl }) => {
  const [isReportLoading, setIsReportLoading] = useState(true);

  const handleReportLoad = () => {
    setIsReportLoading(false);
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left sidebar */}
      <div className="w-64 bg-gray-900 text-white flex flex-col">
        <img src="yd_logo_white.png" alt="yd_logo" className="p-4 max-w-full" />
        <div className="p-4">
        </div>
        
        {/* Menu options */}
        <nav className="flex-grow">
          <ul className="space-y-2 p-4">
            <li className="flex items-center space-x-2 p-2 bg-gray-800 rounded">
              <BarChart2 size={20} />
              <span>My Dashboard</span>
            </li>
            <li className="flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
              <Activity size={20} />
              <span>Activity</span>
            </li>
            <li className="flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
              <BarChart2 size={20} />
              <span>Insights</span>
            </li>
            <li className="flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
              <Users size={20} />
              <span>Users</span>
            </li>
            <li className="flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
              <Search size={20} />
              <span>Queries</span>
            </li>
            <li className="flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
              <Settings size={20} />
              <span>Admin</span>
            </li>
          </ul>
        </nav>
        
        {/* Bottom buttons */}
        <div className="p-4">
          <button className="w-full mb-2 flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
            <HelpCircle size={20} />
            <span>Support</span>
          </button>
          <button className="w-full mb-2 flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
            <Settings size={20} />
            <span>Settings</span>
          </button>
          <button className="w-full flex items-center space-x-2 p-2 hover:bg-gray-800 rounded">
            <LogOut size={20} />
            <span>Log out</span>
          </button>
        </div>
      </div>
      
      {/* Main content area */}
      <div className="flex-grow p-1 overflow-hidden">
        <div className="flex justify-between items-center mb-6">
        </div>
        
        {/* Embedded Google Looker Studio Report */}
        <div className="relative h-[calc(100vh-15px)]">
          {isReportLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
            </div>
          )}
          <iframe
            src={lookerReportUrl}
            width="100%"
            height="100%"
            style={{ border: 'none' }}
            onLoad={handleReportLoad}
            title="Mai Analytics Dashboard"
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;